import QRCode from "react-qr-code";
import "./print-physical-count.scss";
import { GetInventoryDto } from "@api/api";
import { currencyFormat } from "@shared/formats/currency-format";
import { dateFormat } from "@shared/formats/date-time-format";
import classNames from "classnames";

export interface PrintPhysicalCountField {
  accountableOfficer: string;
  officialDesignation: string;
  agency: string;
  assumptionDate: string;
  propertyNumber: string;
  assetManagementDivisionHead: string;
  cgsoHead: string;
  supplyOfficer: string;
  newPropertyNumber: string;
}
export interface PrintPhysicalCountProps {
  data: GetInventoryDto;
  field: PrintPhysicalCountField;
}

export function PrintPhysicalCount({ data, field }: PrintPhysicalCountProps) {
  const qrCode = `MONITORCODE-${data?.code}`;
  return (
    <section>
      <style type="text/css" media="print">
        {
          "\
  @page { size: legal landscape; }\
"
        }
      </style>

      <div className="print-physical-count my-10">
        <header className="flex">
          <div className="relative top-0 mx-4 w-full text-center">
            <h2>
              REPORT ON THE PHYSICAL COUNT OF PROPERTY PLANT AND EQUIPMENT
            </h2>
            <h2>OFFICE EQUIPMENT, IT, MOTOR VEHICLE AND OTHER EQUIPMENT</h2>
            <h3>(Type of Property, Plant & Equipment)</h3>
            <h4>As of {dateFormat(data.date_assigned)}</h4>
          </div>
          <div className="mx-4">
            <QRCode value={qrCode} size={120} />
          </div>
        </header>

        <section className="mb-4 mt-24 text-center">
          <table className="ml-auto mr-auto">
            <tbody>
              <tr>
                <td>For which I</td>
                <td>
                  <span className="font-bold border-b border-black px-4">
                    {field.accountableOfficer}
                  </span>
                  ,
                </td>
                <td>
                  <span className="font-bold border-b border-black px-4">
                    {field.officialDesignation}
                  </span>
                  ,
                </td>
                <td>
                  <span className="font-bold border-b border-black px-4">
                    {field.agency}
                  </span>
                </td>
                <td>is accountable, having assumed such accountability on </td>
                <td>
                  <span className="font-bold border-b border-black px-4">
                    {field.assumptionDate}
                  </span>
                </td>
              </tr>
              <tr>
                <td></td>
                <td>
                  <small>(Name of Accountable Officer)</small>
                </td>
                <td>
                  <small>(Official Designation)</small>
                </td>
                <td>
                  <small>(Agency / Office)</small>
                </td>
                <td></td>
                <td>
                  <small>(Date of Assumption)</small>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="mt-10">
          <table className="print-table w-full text-sm text-left rtl:text-right text-gray-500 border-t-2 border-b-2 border-l-2 border-r-2">
            <thead className="border-t border-b border-l-0 border-r-0">
              <tr className="thead head-1">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>

                <td colSpan={2}></td>
                <th colSpan={8} className="border-b text-center">
                  Remarks
                </th>
              </tr>
              <tr className="thead head-2">
                <td></td>
                <td></td>
                <th>Property</th>
                <th>Unit Of</th>
                <th>Date</th>
                <th>Acquisition</th>
                <th>Unit</th>
                <th>Balance</th>
                <th>On-Hand</th>

                <td colSpan={2}></td>
                <th className="border-b text-center" colSpan={4}>
                  Transferred/Donations by
                </th>
                <td></td>
                <td></td>
                <td></td>
                <th>Other</th>
              </tr>
              <tr className="thead head-3">
                <th>Article</th>
                <th>Description</th>
                <th>Number</th>
                <th>Measure</th>
                <th>Acquired</th>
                <th>Cost</th>
                <th>Value</th>
                <th>Per Card</th>
                <th>Per Count</th>
                <th className="border-b text-center" colSpan={2}>
                  Shortage/Overage
                </th>
                <th>DepEd Division</th>
                <th colSpan={2}>DepEd Region</th>
                <th>PTA</th>
                <th>Functional</th>
                <th>Unserviceable</th>
                <th>with MRI</th>
                <th>Information</th>
              </tr>
              <tr className="thead head-4">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <th>(Quantity)</th>
                <th>(Quantity)</th>
                <th>Quantity</th>
                <th>Value</th>
                <th>Office</th>
                <th>Central Office</th>
                <th>LGU's</th>
                <th>Others</th>
                <th>Operational</th>
                <td></td>
                <th>PAR</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              <tr className="border-t border-b border-l-0 border-r-0">
                <td className="tdata"></td>
                <td className="tdata">
                  <span>{data?.item_name}</span>
                  <small className="block my-2">Item</small>
                  <pre className="w-full whitespace-pre-wrap">
                    {data?.item_description}
                  </pre>
                  <small className="block my-2">Delivery</small>
                  <pre className="w-full whitespace-pre-wrap">
                    {data?.delivery_description}
                  </pre>
                </td>
                <td className="tdata">{field.propertyNumber}</td>
                <td className="tdata">{data.unit_name}</td>
                <td className="tdata">{dateFormat(data?.date_assigned)}</td>
                <td className="tdata">
                  {currencyFormat(data?.item_price, "PHP")}
                </td>
                <td className="tdata">
                  {currencyFormat(data?.item_price, "PHP")}
                </td>
                <td className="tdata">1</td>
                <td className="tdata">1</td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
                <td className="tdata"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr>
                <td className="border px-2 pt-4 pb-1 " colSpan={20}>
                  <section className="grid grid-cols-3 grid-rows-1 gap-4 py-1 mt-16">
                    <div>
                      <h5 className="print-normal block mb-5">Prepared:</h5>
                      <div className="flex flex-col items-center justify-center">
                        <div className="print-normal font-bold text-sm">
                          {field.assetManagementDivisionHead}
                        </div>
                        <small
                          className={classNames(
                            "print-normal mb-6 block relative top-[1px] border-t border-black w-3/4 text-center"
                          )}
                        >
                          Asset Management Division Head
                        </small>
                      </div>
                    </div>

                    <div>
                      <h5 className="print-normal block mb-5">Noted:</h5>
                      <div className="flex flex-col items-center justify-center">
                        <div className="print-normal font-bold text-sm">
                          {field.cgsoHead}
                        </div>
                        <small
                          className={classNames(
                            "print-normal mb-6 block relative top-[1px] border-t border-black w-3/4 text-center"
                          )}
                        >
                          CGSO-Head
                        </small>
                      </div>
                    </div>

                    <div>
                      <h5 className="print-normal block mb-5"></h5>
                      <div className="flex flex-col items-center justify-center">
                        <div className="print-normal font-bold text-sm mt-4">
                          {field.supplyOfficer}
                        </div>
                        <small
                          className={classNames(
                            "print-normal mb-6 block relative top-[1px] border-t border-black w-3/4 text-center"
                          )}
                        >
                          Supply Officer
                        </small>
                      </div>
                    </div>
                  </section>
                </td>
              </tr>
            </tfoot>
          </table>
        </section>
      </div>
    </section>
  );
}

export default PrintPhysicalCount;
