import { SETTINGS } from "@core/utility/settings";
import {
  AccountFormSchema,
  BidderFormSchema,
  DeliveryFormSchema,
  InventoryFormSchema,
  ItemFormSchema,
  OrderFormSchema,
  RequestFormSchema,
} from "./form.rule";

export const requestFormDefault = {
  prno: "",
  dueDate: "" as any,
  category: "",
  section: "",
  sai: "",
  saiDate: "" as any,
  alobs: "",
  alobsDate: "" as any,
  purpose: "",
  items: [],
  active: true,
  urgent: false,
  department: "",
  departmentLabel: "",
  isPPMP: false,
  isActivityDesign: false,
  signatoryName: "",
  hasApp: false,
  hasPow: false,
  hasBarchart: false,
  hasTechSpec: false,
  hasPlan: false,
  hasQuitClaim: false,
  status: "",
  remarks: "",
} as RequestFormSchema;

export const orderFormDefault = {
  pono: "",
  poDate: "" as any,
  resolutionNo: "",
  procurementMode: "",
  supplier: "",
  address: "",
  email: "",
  phone: "",
  tin: "",
  deliveryAddress: "",
  deliveryDate: "" as any,
  deliveryTerm: "",
  paymentTerm: "",
  isActive: true,
  requests: [],
  category: "",
  categoryName: "",
  iar: "",
  iarDate: "" as any,
  invoice: "",
  invoiceDate: "" as any,
  signatoryName1: SETTINGS.signatoryName1,
  signatoryOffice1: SETTINGS.signatoryOffice1,
  signatoryName2: SETTINGS.signatoryName2,
  signatoryOffice2: SETTINGS.signatoryOffice2,
  endUserName1: SETTINGS.endUserName1,
  endUserOffice1: SETTINGS.endUserOffice1,
  rfqNumber: "",
  itbNumber: "",
} as OrderFormSchema;

export const deliveryFormDefault = {
  code: "",
  prCode: "",
  prItemCode: "",
  deliveredQuantity: 0,
  brand: "",
  brandName: "",
  description: "",
  itemDetails: undefined,
} as DeliveryFormSchema;

export const bidderFormDefault = {
  country: "Philippines",
  name: "",
  email: "",
  mobile: "",
  streetName: "",
  barangay: "",
  city: "",
} as BidderFormSchema;

export const accountFormDefault = {
  country: "Philippines",
  name: "",
  lastName: "",
  email: "",
  mobile: "",
  streetName: "",
  barangay: "",
  city: "",
  role: "",
  department: "",
  username: "",
  password: "",
} as AccountFormSchema;

export const itemFormDefault = {
  name: "",
  brand: "",
  category: "",
  description: "",
  isActive: true,
  cost: 0,
  unit: "",
  quantity: 0,
  deliveredQuantity: 0,
  prCode: "",
} as ItemFormSchema;

export const inventoryFormDefault = {
  code: "",
  batch: "",
  inventoryNo: "",
  lot: "",
  office: "",
  building: "",
  endOfLife: 0,
  assignee: "",
  dateAssigned: "" as any,
  propertyType: "",
  remarks: "",
  status: "",
  status_name: "",
  assignedDepartment: "",
  poNo: "",
  poDate: "" as any,
  poCategory: "",
  procurementMode: "",
  resolutionNo: "",
  iarNo: "",
  supplier: "",
  supplierAddress: "",
  supplierEmail: "",
  supplierContact: "",
  supplierTin: "",
  prNo: "",
  prDate: "" as any,
  prCategory: "",
  prDepartment: "",
  prSection: "",
  prPurpose: "",
  itemCode: "",
  itemName: "",
  itemPrice: 0,
  unit: "",
  deliveryBrand: "",
  deliveryDescription: "",
  parNo: "",
  icsNo: "",
  airNo: "",
  entityName: "",
  receivedFrom: "",
  receivedFromPosition: "",
  receivedFromDate: "",
  receivedBy: "",
  receivedByPosition: "",
  accountableOfficer: "",
  officialDesignation: "",
  agency: "",
  assumptionDate: "",
  propertyNumber: "",
  assetManagementDivisionHead: "",
  cgsoHead: "",
  supplyOfficer: "",
  newPropertyNumber: "",
  expenseCode: "",
  serialNo: "",
} as InventoryFormSchema;
