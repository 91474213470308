export enum InventoryStatus {
  PRE_REG = "PRE_REG",
  REG = "REG",
  DMG = "DMG",
  REPAIR = "REPAIR",
  LOST = "LOST",
  STOLEN = "STOLEN",
  DISPOSED = "DISPOSED",
  RETURNED = "RETURNED",
  ASSIGNED = "ASSIGNED",
}
