import { useEffect, useRef, useState } from "react";
import "./text-to-image.scss";

export interface TextToImageProps {
  text: string;
}

export function TextToImage({ text }: TextToImageProps) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [imageSrc, setImageSrc] = useState<string | null>(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (canvas) {
      const context = canvas.getContext("2d");
      if (context) {
        // Set canvas size
        canvas.width = 300;
        canvas.height = 100;

        // Set text properties
        context.font = "50px Arial";
        context.fillStyle = "black";
        context.textAlign = "center";
        context.textBaseline = "middle";

        // Draw text on canvas
        context.clearRect(0, 0, canvas.width, canvas.height); // Clear the canvas
        context.fillText(text, canvas.width / 2, canvas.height / 2);

        // Convert canvas to data URL
        const dataUrl = canvas.toDataURL();
        setImageSrc(dataUrl);
      }
    }
  }, [text]);

  return (
    <div className="text-to-image w-full">
      <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
      {imageSrc && <img src={imageSrc} alt={text} className="w-full" />}
    </div>
  );
}

export default TextToImage;
