export enum QueryKey {
  Bidder = "bidder",
  Item = "item",
  Brand = "brand",
  Unit = "unit",
  Category = "category",
  Request = "request",
  Account = "account",
  Role = "role",
  Department = "department",
  Authentication = "authentication",
  Stage1 = "stage1",
  Stage2 = "stage2",
  Stage3 = "stage3",
  Stage4 = "stage4",
  Stage1Review = "stage-1-review",
  Stage3Review = "stage-3-review",
  Status = "status",
  Order = "order",
  Delivery = "delivery",
  RequestsInOrder = "requests-in-orders",
  History = "history",
  PRReport = "pr-report",
  POReport = "po-report",
  InventoryReport = "inventory-report",
  Notification = "notification",
  Inventory = "inventory",
  InventoryStatus = "inventory-status",
}
