import { GetInventoryDto } from "@api/api";
import "./print-par-form.scss";
import QRCode from "react-qr-code";
import classNames from "classnames";
import { dateFormat } from "@shared/formats/date-time-format";
import { currencyFormat } from "@shared/formats/currency-format";

export interface PrintParFormProps {
  data: GetInventoryDto;
  airNo?: string;
  entityName?: string;
  receivedFrom?: string;
  receivedFromPosition?: string;
  receivedFromDate?: string;
  receivedBy?: string;
  receivedByPosition?: string;
}

export function PrintParForm({
  data,
  airNo,
  entityName,
  receivedFrom,
  receivedFromPosition,
  receivedFromDate,
  receivedBy,
  receivedByPosition,
}: PrintParFormProps) {
  const qrCode = `MONITORCODE-${data?.code}`;
  return (
    <div className="print-par-form m-8 border-solid border-2 border-gray-500 p-8">
      <style type="text/css" media="print">
        {
          "\
  @page { size: A4; }\
"
        }
      </style>
      <header className="flex">
        <div className="relative top-0 mx-4 w-full text-center">
          <h2>Property Acknowledgement Receipt</h2>
          <small>(PAR)</small>
        </div>
        <div className="mx-4">
          <QRCode value={qrCode} size={120} />
        </div>
      </header>

      <section className="grid grid-cols-2 grid-rows-1 gap-4 mb-4 mt-24">
        <div>
          <span className="flex items-center gap-2">
            <label className="print-normal whitespace-nowrap font-bold">
              AIR NO.:{" "}
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full",
                airNo ? "top-[1px]" : "top-[10px]"
              )}
            >
              {airNo}
            </h4>
          </span>
          <span className="flex items-center gap-2">
            <label className="print-normal whitespace-nowrap font-bold">
              Entity Name:
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full",
                entityName ? "top-[1px]" : "top-[10px]"
              )}
            >
              {entityName}
            </h4>
          </span>
          <span className="flex items-center gap-2">
            <label className="print-normal whitespace-nowrap font-bold">
              Supplier
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full",
                data?.supplier ? "top-[1px]" : "top-[10px]"
              )}
            >
              {data?.supplier}
            </h4>
          </span>
        </div>
        <div className="ml-16">
          <span className="flex items-center gap-2">
            <label className="whitespace-nowrap print-normal font-bold">
              PO No.:
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full",
                data?.po_no ? "top-[1px]" : "top-[10px]"
              )}
            >
              {data?.po_no}
            </h4>
          </span>
          <span className="flex items-center gap-2">
            <label className="whitespace-nowrap print-normal font-bold">
              PAR No.:{" "}
            </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full",
                data?.par_no ? "top-[1px]" : "top-[10px]"
              )}
            >
              {data?.par_no}
            </h4>
          </span>
          <span className="flex items-center gap-2">
            <label className="print-normal font-bold">Date: </label>
            <h4
              className={classNames(
                "print-normal block relative font-bold border-b border-black w-full top-[1px]"
              )}
            >
              {dateFormat(new Date().toString())}
            </h4>
          </span>
        </div>
      </section>

      <section className="mt-10">
        <table className="print-table w-full text-sm text-left rtl:text-right text-gray-500 border-t-2 border-b-2 border-l-2 border-r-2">
          <tbody className="border-t border-b border-l-0 border-r-0">
            <tr>
              <th className="print-normal px-2 py-1 border  w-20">Quantity</th>
              <th className="print-normal px-2 py-1 border ">Unit</th>
              <th className="print-normal px-2 py-1 border ">Unit Cost</th>
              <th className="print-normal px-2 py-1 border ">Total Cost</th>
              <th className="print-normal px-2 py-1 border ">Description</th>
              <th className="print-normal px-2 py-1 border ">
                Inventory Item No.
              </th>
              <th className="print-normal px-2 py-1 border ">
                Estimated Useful Life
              </th>
            </tr>

            <tr className="border-t border-b border-l-0 border-r-0">
              <td className="print-normal px-2 py-1 border ">1</td>
              <td className="print-normal px-2 py-1 border ">
                {data?.unit_name}
              </td>
              <td className="print-normal px-2 py-1 border ">
                {currencyFormat(data?.item_price || 0, "PHP")}
              </td>
              <td className="print-normal px-2 py-1 border ">
                {currencyFormat(data?.item_price || 0, "PHP")}
              </td>
              <td className="print-normal px-2 py-1 border ">
                {data?.delivery_description}
              </td>
              <td className="print-normal px-2 py-1 border ">
                {data?.end_of_life}
              </td>
            </tr>
            <tr>
              <td colSpan={7} className="pb-[280px]"></td>
            </tr>
            <tr>
              <td className="border px-2 pt-4 pb-1 " colSpan={7}>
                <section className="grid grid-cols-2 grid-rows-1 gap-4 py-1">
                  <div>
                    <h5 className="print-normal block mb-5">Received From:</h5>
                    <div className="flex flex-col items-center justify-center">
                      <div className="print-normal font-bold text-sm">
                        {receivedFrom}
                      </div>
                      <small
                        className={classNames(
                          "print-normal mb-6 block relative top-[1px] border-t border-black w-3/4 text-center"
                        )}
                      >
                        Name
                      </small>
                      <div className="print-normal font-bold text-sm">
                        {receivedFromPosition}
                      </div>
                      <small
                        className={classNames(
                          "print-normal mb-6 block relative top-[1px] border-t border-black w-2/4 text-center"
                        )}
                      >
                        Position
                      </small>
                      <div className="print-normal font-bold text-sm">
                        {receivedFromDate}
                      </div>
                      <small
                        className={classNames(
                          "print-normal mb-6 block relative top-[1px] border-t border-black w-2/4 text-center"
                        )}
                      >
                        Date
                      </small>
                    </div>
                  </div>
                  <div>
                    <h5 className="print-normal block mb-5">Received By:</h5>
                    <div className="flex flex-col items-center justify-center">
                      <div className="print-normal font-bold text-sm">
                        {receivedBy}
                      </div>
                      <small
                        className={classNames(
                          "print-normal mb-6 block relative top-[1px] border-t border-black w-3/4 text-center"
                        )}
                      >
                        Name
                      </small>
                      <div className="print-normal font-bold text-sm">
                        {receivedByPosition}
                      </div>
                      <small
                        className={classNames(
                          "print-normal mb-6 block relative top-[1px] border-t border-black w-2/4 text-center"
                        )}
                      >
                        Position
                      </small>
                    </div>
                  </div>
                </section>
              </td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  );
}

export default PrintParForm;
