import { useFormContext } from "react-hook-form";
import "./form-monitor-print-fields.scss";
import { InventoryFormSchema } from "@core/model/form.rule";
import InputControl from "@shared/ui/hook-form/input-control/input-control";
import { Divider } from "primereact/divider";

export function FormMonitorPrintFields() {
  const { control } = useFormContext<InventoryFormSchema>();
  return (
    <div className="form-monitor-print-fields">
      <InputControl<InventoryFormSchema>
        control={control}
        name="airNo"
        label="AIR No. (Print Only)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter AIR number"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="entityName"
        label="Entity Name (Print Only)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
        placeholder="Enter Entity"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="receivedFrom"
        label="Received From (Print Only)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="receivedFromPosition"
        label="Received From Position (Print Only)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="receivedFromDate"
        label="Received From Date (Print Only)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="receivedBy"
        label="Received By (Print Only)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="receivedByPosition"
        label="Received By Position (Print Only)"
        className="w-full md:w-3/4"
        containerClassName="pb-2"
      />

      <Divider />
      <InputControl<InventoryFormSchema>
        control={control}
        name="accountableOfficer"
        label="Accountable Officer (Print Only - Physical Count/Inventory Report of Equipment)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="officialDesignation"
        label="Official Designation (Print Only - Physical Count/Inventory Report of Equipment)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="agency"
        label="Agency (Print Only - Physical Count/Inventory Report of Equipment)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="assumptionDate"
        label="Assumption Date (Print Only - Physical Count)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="propertyNumber"
        label="Property Number (Print Only - Physical Count)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="newPropertyNumber"
        label="New Property Number (Print Only - Inventory Report of Equipment)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="assetManagementDivisionHead"
        label="Asset Management Division Head (Print Only - Physical Count)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="cgsoHead"
        label="CGSO Head (Print Only - Physical Count)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="supplyOfficer"
        label="Supply Officer (Print Only - Physical Count)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="expenseCode"
        label="Expense Code (COA)"
        className="w-full"
        containerClassName="pb-2"
      />
      <InputControl<InventoryFormSchema>
        control={control}
        name="serialNo"
        label="Serial Number (COA)"
        className="w-full"
        containerClassName="pb-2"
      />
    </div>
  );
}

export default FormMonitorPrintFields;
