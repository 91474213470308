export const activeClass =
  "bg-gradient-to-tr from-caribbean-green-200 to-caribbean-green-100 text-gray-800";
export const hoverClass = "hover:bg-caribbean-green-100";
export const homeNav = {
  title: "Home",
  icon: "pi pi-home",
  path: "",
  activeClass,
  hoverClass,
};
export const requestHomeNav = {
  title: "Home",
  icon: "pi pi-home",
  path: "rhome",
  activeClass,
  hoverClass,
};
export const requestNav = {
  title: "Purchase Request",
  icon: "pi pi-book",
  path: "request",
  activeClass,
  hoverClass,
};
export const orderNav = {
  title: "Purchase Order",
  icon: "pi pi-shopping-cart",
  path: "order",
  activeClass,
  hoverClass,
};
export const itemNav = {
  title: "Item",
  icon: "pi pi-shopping-bag",
  path: "item",
  activeClass,
  hoverClass,
};
export const bidderNav = {
  title: "Bidder",
  icon: "pi pi-briefcase",
  path: "bidder",
  activeClass,
  hoverClass,
};
export const accountNav = {
  title: "Account",
  icon: "pi pi-users",
  path: "account",
  activeClass,
  hoverClass,
};
export const monitorNav = {
  title: "Monitoring",
  icon: "pi pi-book",
  path: "monitor",
  activeClass,
  hoverClass,
};
export const notificationNav = {
  title: "Notification",
  icon: "pi pi-bell",
  path: "",
  activeClass,
  hoverClass,
  isAction: true,
};
export const reportNav = {
  title: "Report",
  icon: "pi pi-chart-pie",
  path: "report",
  activeClass,
  hoverClass,
};
export const moreNav = {
  title: "More",
  icon: "pi pi-bars",
  path: "more",
  isMore: true,
  activeClass,
  hoverClass,
};
export const logoutNav = {
  title: "Logout",
  icon: "pi-power-off",
  path: "login",
  isMore: false,
  activeClass,
  hoverClass,
};
