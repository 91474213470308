import QRCode from "react-qr-code";
import "./print-inventory-qr.scss";
import { InputText } from "primereact/inputtext";
import classNames from "classnames";

export interface PrintInventoryQrProps {
  code: string;
  propertyNo: string;
  expenseCode: string;
  serialNo: string;
}

export function PrintInventoryQr({
  code,
  propertyNo,
  expenseCode,
  serialNo,
}: PrintInventoryQrProps) {
  const qrCode = `MONITORCODE-${code}`;
  const logo = "/tagbilaran-logo.png";
  return (
    <div className="print-inventory-qr">
      <style type="text/css" media="print">
        {
          "\
        @page { size: landscape; }\
      "
        }
      </style>

      <div className="flex gap-10 justify-center items-center h-screen">
        <div className="flex items-center flex-col gap-4">
          <header className="flex w-full my-4 justify-center items-center gap-6">
            <div className="relative top-0 mx-4">
              <img
                src={logo}
                className="w-28"
                alt="city of tagbilaran official seal"
              />
            </div>
            <div className="flex flex-col items-center">
              <span>REPUBLIC OF THE PHILIPPINES</span>
              <h2 className="block">CITY OF TAGBILARAN</h2>
              <h4 className="">OFFICE OF THE CITY MAYOR</h4>
            </div>
          </header>
          <section>
            <div>
              <label className="block">PROPERTY NO.</label>
              <InputText value={propertyNo} className="w-96" />
            </div>
            <div>
              <label className="block">Expense Code</label>
              <InputText value={expenseCode} className="w-96" />
            </div>
            <div>
              <label className="block">Serial No</label>
              <InputText value={serialNo} className="w-96" />
            </div>
          </section>
          <section>
            <div className="text-center mb-6">NOTED:</div>
            <div className="flex gap-8 mb-8">
              <div className="flex flex-col items-center justify-center">
                <div className="print-normal font-bold text-sm"></div>
                <small
                  className={classNames(
                    "print-normal block relative top-[1px] border-t border-black w-48 text-center"
                  )}
                >
                  CMO Representative
                </small>
              </div>
              <div className="flex flex-col items-center justify-center">
                <div className="print-normal font-bold text-sm"></div>
                <small
                  className={classNames(
                    "print-normal block relative top-[1px] border-t border-black w-48 text-center"
                  )}
                >
                  GSO Representative
                </small>
              </div>
            </div>
            <div className="flex justify-center">
              <div className="flex flex-col items-center justify-center">
                <div className="print-normal font-bold text-sm"></div>
                <small
                  className={classNames(
                    "print-normal block relative top-[1px] border-t border-black w-48 text-center"
                  )}
                >
                  COA Representative
                </small>
              </div>
            </div>
          </section>
        </div>

        <div className="flex mx-4 items-center justify-center">
          <QRCode value={qrCode} size={220} />
        </div>
      </div>
    </div>
  );
}

export default PrintInventoryQr;
