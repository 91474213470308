import "./item-bulk-preview.scss";
import { DataTable } from "primereact/datatable";
import { useAddItem, useAddItemBulk } from "@core/query/item.query";
import { MessageResponseDto } from "@api/api";
import {
  PurchaseItemFormSchema,
  RequestFormSchema,
} from "@core/model/form.rule";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { FormToApiService } from "@core/services/form-to-api.service";
import { useNotificationContext } from "@shared/ui/notification/notification.context";
import { useFormContext } from "react-hook-form";
import { Tag } from "primereact/tag";
import { useQyGetCategory } from "@core/query/category.query";
import { useGetUnit } from "@core/query/unit.query";
import { keyBy } from "lodash-es";

export interface ItemBulkPreviewProps {
  bulkItems: PurchaseItemFormSchema[];
  onBulk: () => void;
}

export function ItemBulkPreview({ bulkItems, onBulk }: ItemBulkPreviewProps) {
  const { getValues: getItemsInForm, setValue } =
    useFormContext<RequestFormSchema>();
  const { showSuccess, showWarning } = useNotificationContext();

  // QUERIRES
  const { data: categories } = useQyGetCategory();
  const { data: units } = useGetUnit("", 99999999);
  const unitData = units?.data || [];
  const unitDataRecord = keyBy(unitData, "name");

  const categoryData = categories?.data || [];
  const categoryDataRecord = keyBy(categoryData, "name");

  const handleAddApiSuccess = (response: MessageResponseDto) => {
    const field = response.data as any;

    // descoped
    const updatedBulkItems = bulkItems.map((item) => {
      if (item.name === field.name) {
        return {
          ...item,
          code: field.code,
        };
      }

      return item;
    });
    showSuccess(`${field.name} is now added into the system`);
  };
  const { mutate: addItem, isLoading: isAddingItem } =
    useAddItem(handleAddApiSuccess);

  const handleAddBulkApiSuccess = (response: MessageResponseDto) => {
    const bulkResponse = (response.data as any[]) || [];
    if (bulkResponse.length === 0 && bulkItems.length > 0) {
      handleUpload(bulkItems);
      return;
    }

    if (bulkResponse.length === 0) {
      return;
    }

    const updatedBulkItems = bulkItems.map((item) => {
      const itemCode = bulkResponse.find((code) => code.name === item.name);
      return itemCode ? { ...item, code: itemCode.code } : item;
    });

    handleUpload(updatedBulkItems);
  };
  const { mutate: addBulkItem, isLoading: isAddingBulkItem } = useAddItemBulk(
    handleAddBulkApiSuccess
  );

  // LOCAL FUNCTIONS
  const handleAddBulkItems = () => {
    const unregisteredBulkItems = bulkItems.filter((item) => !item.code);
    const mappedItems = unregisteredBulkItems.map((item) =>
      FormToApiService.NewItem(item)
    );
    addBulkItem({
      items: mappedItems,
    });
  };
  const handleAddItem = (data: PurchaseItemFormSchema) => {
    const formData = FormToApiService.NewItem(data);
    addItem(formData);
  };
  const handleUpload = (bulkItemArray: PurchaseItemFormSchema[]) => {
    const noCodeItems = bulkItemArray.filter((x) => x.code === "" || !x.code);
    if (noCodeItems.length > 0) {
      showWarning(
        `There are ${noCodeItems.length} items that does not exist yet in the system. Kindly add them.`
      );
      return;
    }

    const itemsInForm = getItemsInForm("items");
    let updatedItemsForm = itemsInForm as PurchaseItemFormSchema[];
    bulkItemArray.forEach((item) => {
      let accumulatedItemForm = item;
      if (item.unit === "-") {
        const unitId =
          unitDataRecord?.[accumulatedItemForm.unitName || ""]?.code;
        accumulatedItemForm = {
          ...accumulatedItemForm,
          unit: unitId || "",
          unitName: !!unitId ? accumulatedItemForm.unitName : "",
        };
      }

      if (item.category === "-") {
        const categoryId =
          categoryDataRecord?.[accumulatedItemForm.categoryName || ""]?.code;
        accumulatedItemForm = {
          ...accumulatedItemForm,
          category: categoryId || "",
          categoryName: !!categoryId ? accumulatedItemForm.categoryName : "",
        };
      }

      updatedItemsForm = [
        ...updatedItemsForm,
        accumulatedItemForm,
      ] as PurchaseItemFormSchema[];
    });

    setValue("items", updatedItemsForm);
    onBulk();
  };

  const actionTemplate = (data: PurchaseItemFormSchema) => {
    const noCode = data.code === "" || !data.code;
    return noCode ? <Tag value="New Item"></Tag> : null;
  };

  return (
    <div className="item-bulk-preview">
      <h2 className="block mb-5">Upload Preview</h2>
      <DataTable value={bulkItems}>
        <Column field="categoryName" header="Category"></Column>
        <Column field="unitName" header="Unit"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="description" header="Description"></Column>
        <Column field="cost" header="Cost"></Column>
        <Column field="quantity" header="Quantity"></Column>

        <Column
          header="Action"
          body={(data: PurchaseItemFormSchema) => actionTemplate(data)}
        ></Column>
      </DataTable>
      <Button
        label="Add to Request"
        onClick={() => handleAddBulkItems()}
        disabled={isAddingItem}
        className="block mt-4"
      />
    </div>
  );
}

export default ItemBulkPreview;
