import { useCallback, useEffect, useState } from "react";
import "./qr-scanner.scss";
import { Html5QrcodeScanner } from "html5-qrcode";
import { useNavigate } from "react-router";
import { removePrefix } from "@core/utility/string.helper";

/* eslint-disable-next-line */
export interface QrScannerProps {}

export function QrScanner() {
  let html5QrcodeScanner: any;
  const navigate = useNavigate();
  const [scanResult, setScanResult] = useState("");

  useEffect(() => {
    if (!html5QrcodeScanner?.getState()) {
      html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        { fps: 10, qrbox: { width: 550, height: 550 } },
        false
      );
      html5QrcodeScanner.render(onScanSuccess, undefined);
    }

    function onScanSuccess(decodedText: string, decodedResult: any) {
      console.log(`Code matched = ${decodedText}`, decodedResult);
      setScanResult(decodedText);
      const prCodeMatch = "PRCODE-";
      const poCodeMatch = "POCODE-";
      const monitorCodeMatch = "MONITORCODE-";

      console.log("SUCCESS SCAN");
      if (decodedText.indexOf(prCodeMatch) >= 0) {
        const code = removePrefix(prCodeMatch, decodedText);
        handleNavigate(`/request/${code}/scan`);
      } else if (decodedText.indexOf(poCodeMatch) >= 0) {
        const code = removePrefix(poCodeMatch, decodedText);
        handleNavigate(`/order/${code}/scan`);
      }else if (decodedText.indexOf(monitorCodeMatch) >= 0) {
        const code = removePrefix(monitorCodeMatch, decodedText);
        handleNavigate(`/monitor/${code}`);
      }
    }
  }, [navigate]);

  const handleNavigate = (url: string) => {
    if (html5QrcodeScanner) {
      html5QrcodeScanner
        .clear()
        .then((x: any) => {
          console.log("CLEAR", { x });
        })
        .catch((error: any) => {
          console.error("Failed to clear html5QrcodeScanner. ", error);
        });
    }

    navigate(url);
  };

  return (
    <div className="qr-scanner">
      {scanResult ? (
        <div>Success: The Value is {scanResult}</div>
      ) : (
        <div id="reader"></div>
      )}
    </div>
  );
}

export default QrScanner;
