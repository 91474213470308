import { GetInventoryDto } from "@api/api";
import "./print-inventory-equipment.scss";
import QRCode from "react-qr-code";
import { PrintPhysicalCountField } from "../print-physical-count/print-physical-count";
import { currencyFormat } from "@shared/formats/currency-format";
import { dateFormat } from "@shared/formats/date-time-format";

export interface PrintInventoryEquipmentProps {
  data: GetInventoryDto;
  field: PrintPhysicalCountField;
}

export function PrintInventoryEquipment({
  data,
  field,
}: PrintInventoryEquipmentProps) {
  const qrCode = `MONITORCODE-${data?.code}`;
  return (
    <section>
      <style type="text/css" media="print">
        {
          "\
  @page { size: legal landscape; }\
"
        }
      </style>
      <div className="print-inventory-equipment mx-4 mt-5">
        <header className="flex">
          <div className="relative top-0 mx-4 w-full text-center">
            <h2>INVENTORY OF EQUIPMENT</h2>
            <h3>(Supplies of Equipment but not both)</h3>
            <h4>As of {dateFormat(data.date_assigned)}</h4>
          </div>
          <div className="mx-4">
            <QRCode value={qrCode} size={120} />
          </div>
        </header>

        <section className="mb-4 mt-24 text-center">
          <table className="ml-auto mr-auto">
            <tbody>
              <tr>
                <td>
                  <span className="font-bold border-b border-black px-4">
                    {field.agency}
                  </span>
                </td>
                <td>
                  <span className="font-bold border-b border-black px-4">
                    {field.officialDesignation}
                  </span>
                  ,
                </td>
                <td>
                  <span className="font-bold border-b border-black px-4">
                    {field.accountableOfficer}
                  </span>
                </td>
              </tr>
              <tr>
                <td>
                  <small>(Agency)</small>
                </td>
                <td>
                  <small>(Name of Accountable Officer)</small>
                </td>
                <td>
                  <small>(Designation)</small>
                </td>
              </tr>
            </tbody>
          </table>
        </section>

        <section className="mt-10">
          <table className="print-table w-full text-sm text-left rtl:text-right text-gray-500 border-t-2 border-b-2 border-l-2 border-r-2">
            <thead className="border-t border-b border-l-0 border-r-0">
              <tr className="thead head-1">
                <th colSpan={2} rowSpan={3}>
                  Article / Description
                </th>
                <th>New Property</th>
                <th className="border-b text-center">Unit</th>
                <th>Unit</th>
                <th colSpan={2} className="border-b text-center">
                  Balance Per Stock Card
                </th>
                <th colSpan={2} className="border-b text-center">
                  On Hand Per Count
                </th>
                <th>Date</th>
                <th>Remarks</th>
              </tr>
              <tr className="thead head-2">
                <th>Number</th>
                <th></th>
                <th className="border-b text-center">Value</th>
                <th className="border-b text-center">Qty</th>
                <th className="border-b text-center">Value</th>
                <th className="border-b text-center">Qty</th>
                <th className="border-b text-center">Value</th>
                <th className="border-b text-center">Acquired</th>
                <th className="border-b text-center">State Whereabouts</th>
              </tr>
              <tr className="thead head-3">
                <td></td>
                <td>3</td>
                <td>4</td>
                <td>5</td>
                <td>6</td>
                <td>7</td>
                <td>8</td>
                <td>9</td>
                <td>10</td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  <span>{data?.item_name}</span>
                  <small className="block my-2">Item</small>
                  <pre className="w-full whitespace-pre-wrap">
                    {data?.item_description}
                  </pre>
                  <small className="block my-2">Delivery</small>
                  <pre className="w-full whitespace-pre-wrap">
                    {data?.delivery_description}
                  </pre>
                </td>
                <td className="tdata">{field.propertyNumber}</td>
                <td className="tdata">{field.newPropertyNumber}</td>
                <td className="tdata">{data.unit_name}</td>
                <td className="tdata">
                  {currencyFormat(data?.item_price, "PHP")}
                </td>
                <td>1</td>
                <td className="tdata">
                  {currencyFormat(data?.item_price, "PHP")}
                </td>
                <td>1</td>
                <td className="tdata">
                  {currencyFormat(data?.item_price, "PHP")}
                </td>
                <td className="tdata">{dateFormat(data?.date_assigned)}</td>
                <td className="tdata">{data.remarks}</td>
              </tr>
            </tbody>
          </table>
        </section>
      </div>
    </section>
  );
}

export default PrintInventoryEquipment;
